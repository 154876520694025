import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './PageFooter.styles';
import { aboutGroup, fanGroup, OutLinkGroup, schoolGroup } from './LinkGroup';
import React from 'react';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { Button, ExternalLink, GFImage, Link, PlayOnFooter } from '@gf/cross-platform-lib/components';
import { Text, View } from 'react-native';

const Footer = styled(View)`
  ${styles.footer};
`;

const LinkSection = styled(View)`
  ${styles.linkSection};
`;

const LinkSectionContainer = styled(View)`
  ${styles.linkSectionContainer};
`;

const OutLinkGroupContainer = styled(View)`
  ${styles.outLinkGroupContainer};
  flex-direction: row;
`;

const GetAppLinkContainer = styled(View)`
  ${styles.getAppLinkContainer}
`;
const GetAppLink = styled(View)`
  ${styles.getAppLink}
`;

const SocialLinks = styled(View)`
  ${styles.socialLinks}
`;
const SocialImages = styled(View)`
  ${styles.socialImages}
`;
const Copyright = styled(View)`
  ${styles.copyright};
`;

const TermAndPolicy = styled(View)`
  ${styles.termAndPolicy}
`;

const ExternalLinkText = styled(Text)`
  ${styles.externalLinkText};
`;

const BoldText = styled(Text)`
  ${styles.boldText};
`;

const SocialView = styled(View)`
  ${styles.socialView};
`;

const HorizontalLine = styled(View)`
  ${styles.horizontalLine};
`;

const BadgesWrapper = styled(View)`
  ${styles.badgesWrapper};
`;

const PreferenceCenterButton = styled(Button)`
  ${styles.preferenceCenterButton};
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

export const PageFooter = () => {
  const openPreferenceCenter = () => {
    (window as Window & { OneTrust?: { ToggleInfoDisplay: () => void } }).OneTrust?.ToggleInfoDisplay();
  };

  return (
    <Footer>
      <LinkSection>
        <LinkSectionContainer>
          <OutLinkGroupContainer>
            <OutLinkGroup title={aboutGroup.title} links={aboutGroup.links} />
            <OutLinkGroup title={schoolGroup.title} links={schoolGroup.links} />
            <OutLinkGroup title={fanGroup.title} links={fanGroup.links} />
          </OutLinkGroupContainer>
          <SocialView>
            <GetAppLinkContainer>
              <GetAppLink>
                <BoldText>Get the GoFan app</BoldText>
                <BadgesWrapper>
                  <ExternalLink
                    href={'https://itunes.apple.com/us/app/gofan-high-school-tickets/id1049091284'}
                    title='Get the GoFan app'
                  >
                    <GFImage name={'apple-badge.png'} width={111} height={37} alt='App store icon' />
                  </ExternalLink>
                  <ExternalLink
                    href={'https://play.google.com/store/apps/details?id=co.gofan.fan.iron'}
                    title='Get the GoFan app'
                  >
                    <GFImage name={'google-play-badge.png'} width={124} height={37} alt='Google Play store icon' />
                  </ExternalLink>
                </BadgesWrapper>
              </GetAppLink>
              <SocialLinks>
                <BoldText>Connect with GoFan</BoldText>
                <SocialImages>
                  <ExternalLink href={'https://www.facebook.com/GoFanHs/'} title='Connect with GoFan via Facebook'>
                    <GFImage name={'facebook-icon.png'} width={24} height={24} alt='Facebook' />
                  </ExternalLink>
                  <ExternalLink href={'https://twitter.com/gofanhs'} title='Connect with GoFan via Twitter'>
                    <GFImage name={'twitter-icon.png'} width={24} height={24} alt='Twitter' />
                  </ExternalLink>
                </SocialImages>
              </SocialLinks>
            </GetAppLinkContainer>
          </SocialView>
        </LinkSectionContainer>
        <Copyright>
          <TermAndPolicy>
            <PlayOnFooter />
            <HorizontalLine />
            <PreferenceCenterButton onPress={openPreferenceCenter} title='Button for Cookie Preference Center'>
              <IconWrapper>
                <GFImage name={'preference-center.svg'} width={32} height={32} alt='preference center' />
              </IconWrapper>
              <ExternalLinkText>Your Privacy Choices</ExternalLinkText>
            </PreferenceCenterButton>
            <Link href={GOFAN_APP_PAGES['privacy-policy'].path} title='Link to Privacy Policy'>
              <ExternalLinkText>Privacy Policy</ExternalLinkText>
            </Link>
            <Link href={GOFAN_APP_PAGES['terms-of-use'].path} title='Link to Terms of Use'>
              <ExternalLinkText>Terms of Use</ExternalLinkText>
            </Link>
          </TermAndPolicy>
          <ExternalLinkText>
            Ⓒ 2015-{new Date().getFullYear()} Huddle Tickets, LLC. All rights reserved.
          </ExternalLinkText>
        </Copyright>
      </LinkSection>
    </Footer>
  );
};
