import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  linkSection: css`
    box-sizing: border-box;
    max-width: 1072px;
    padding: 0 22px;
    width: 100%;
  `,
  linkSectionContainer: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `,
  outLinkGroupContainer: css`
    display: flex;
    flex: 1;
    gap: 3rem;
    @media (max-width: 32.5rem) {
      gap: 1rem;
    }
    flex-wrap: wrap;
    width: 100%;
  `,
  getAppLinkContainer: css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
  `,
  getAppLink: css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    a {
      height: 2.25rem;
    }

    p {
      font-family: Rubik-SemiBold, sans-serif;
      font-weight: 600;
      height: 1.25rem;
      line-height: 1.25rem;
    }
  `,
  socialLinks: css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
      font-family: Rubik-SemiBold, sans-serif;
      font-weight: 600;
      height: 1.25rem;
      line-height: 1.25rem;
    }
  `,
  socialImages: css`
    flex-direction: row;
    gap: 16px;
  `,
  copyright: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 32.5rem) {
      justify-content: center;
    }
    color: rgb(91, 91, 91);
    margin-top: 50.5px;

    p {
      font-size: 0.75rem;
      height: 1rem;
      letter-spacing: 0.32px;
      line-height: 1rem;
    }
  `,
  termAndPolicy: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;

    a {
      color: rgb(91, 91, 91);
      font-family: Rubik-Regular, sans-serif;
      font-size: 0.75rem;
      height: 1rem;
      letter-spacing: 0.32px;
      line-height: 1rem;
    }
  `,
  socialView: css`
    flex-direction: row;
  `,
  boldText: css`
    color: #161616;
    font-family: Rubik-SemiBold;
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0.16px;
    line-height: 20px;
    text-align: right;
    width: auto;
  `,
  externalLinkText: css`
    color: #5b5b5b;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0.32px;
    line-height: 16px;
    width: auto;
  `,
  footer: css`
    align-items: center;
    height: 252px;
    margin-top: auto;
    padding: 32px 0;
    width: 100%;
    z-index: -1;
  `,
  badgesWrapper: css`
    flex-direction: row;
    gap: 16px;
  `,
  horizontalLine: css`
    border: 0.5px solid #c6c6ca;
    width: 0.5px;
    height: 32px;
  `,
  preferenceCenterButton: css`
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
  `,
  iconWrapper: css`
    padding-right: 4px;
  `
};
