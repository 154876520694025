import { GetCurrentApplicationConfiguration, GetCurrentEnvironmentName } from '@gf/cross-platform-lib/utils';
import { Platform } from 'react-native';

export type PageCustomTargetingParameters = {
  s1?: string;
  pid?: string;
  refreshCount?: string;
  adtest?: string;
};

export type SectionCustomTargetingParameters = {
  'school-id'?: string;
  'school-state'?: string;
  activity?: string;
};

export type SlotCustomTargetingParameters = {
  pos?: Pos;
};

export type GetAdReturnType = {
  unitId: string;
  sizes: string | string[] | [number, number] | [number, number][];
  slotId?: string;
  customTargeting?: { [key: string]: string | string[] };
};

export type AdBannerProps = {
  page: AdPageType;
  pageCustomTargetingParameters?: PageCustomTargetingParameters;
  sectionCustomTargetingParameters?: SectionCustomTargetingParameters;
  slotCustomTargetingParameters?: SlotCustomTargetingParameters;
};

// Defined GoFan GAM Tagging Architecture values from spreadsheet provided by OAO
export enum AdPageType {
  HOMEPAGE = 'hp',
  EVENT_LIST = 'event-list',
  ORDER_CONFIRMATION = 'order-confirmation',
  MY_TICKETS = 'mytickets',
  PROFILE = 'profile',
  FAVORITES = 'favorites',
  OTHER = 'other',
  ORDER_CHECKOUT = 'order-checkout'
}

export type Pos = 'inline-1' | 'inline-2' | 'top' | 'bottom';
export const getPlatformString = () => {
  switch (Platform.OS) {
    case 'android':
      return 'gofan_android';
    case 'ios':
      return 'gofan_ios';
    default:
      return 'gofan';
  }
};

export const getAdProps = (
  adPageType: AdPageType,
  isNative: boolean,
  isMobileWeb: boolean,
  isTablet: boolean,
  pageCustomTargetingParameters?: PageCustomTargetingParameters,
  sectionCustomTargetingParameters?: SectionCustomTargetingParameters,
  slotCustomTargetingParameters?: SlotCustomTargetingParameters
): GetAdReturnType => {
  const config = GetCurrentApplicationConfiguration();
  const env = GetCurrentEnvironmentName();
  const platform = getPlatformString();
  const gamNetworkId = config.gam_network_id;
  const pos = slotCustomTargetingParameters?.pos || 'inline-1';
  const adPageString = adPageType === AdPageType.ORDER_CHECKOUT ? AdPageType.ORDER_CONFIRMATION : adPageType;
  const unitId =
    env === 'production' || env === 'staging'
      ? '/' + gamNetworkId + '/' + platform + '/' + adPageString + '/' + pos
      : isNative
      ? Platform.OS === 'ios'
        ? 'ca-app-pub-3940256099942544/2934735716'
        : 'ca-app-pub-3940256099942544/6300978111'
      : '/6355419/Travel/Europe';
  const sizes = getAdSize(adPageType, isTablet, isNative, isMobileWeb, slotCustomTargetingParameters?.pos);
  const customTargeting = {
    s1: adPageString,
    pid: `gofan-${platform}-${adPageType}`,
    pos,
    ...slotCustomTargetingParameters,
    ...pageCustomTargetingParameters,
    ...sectionCustomTargetingParameters
  };

  return {
    unitId,
    sizes,
    slotId: adPageType + '-' + pos,
    customTargeting
  } as GetAdReturnType;
};

type AdGAMProps = {
  responsiveMappingName?: string;
  desktop: [number, number] | [number, number][];
  tablet: [number, number] | [number, number][];
  phone: [number, number] | [number, number][];
  native: string[];
};

// react-native-goolge-mobile-ads GAMBannerAdSize enum mapping. assumes phone size only has 1 array
const adSizeNativeMappings: Record<string, string[]> = {
  '320,50': ['BANNER'],
  '468,60': ['FULL_BANNER'],
  '320,100': ['LARGE_BANNER'],
  '728,90': ['LEADERBOARD'],
  '970,90': ['LEADERBOARD'],
  '300,250': ['MEDIUM_RECTANGLE']
};

// Defined GoFan GAM Tagging Architecture values from spreadsheet provided by OAO
export const getDefinedAdMappings = (adPageType: AdPageType, pos?: Pos): AdGAMProps => {
  const definedAdMappings: Record<string, AdGAMProps> = {
    [AdPageType.HOMEPAGE]: {
      responsiveMappingName: 'leaderboard',
      desktop: [
        [970, 90],
        [728, 90]
      ],
      tablet: [728, 90],
      phone: [320, 50],
      native: adSizeNativeMappings['320,50']
    },
    [AdPageType.EVENT_LIST]: {
      responsiveMappingName: 'leaderboard',
      desktop: [
        [970, 90],
        [728, 90]
      ],
      tablet: [728, 90],
      phone: [320, 50],
      native: adSizeNativeMappings['320,50']
    },
    [AdPageType.ORDER_CONFIRMATION]: {
      responsiveMappingName: 'leaderboard',
      desktop:
        pos === 'inline-1'
          ? [
              [970, 90],
              [728, 90]
            ]
          : [[728, 90]],
      tablet: [728, 90],
      phone: pos === 'inline-1' ? [320, 50] : [320, 100],
      native: pos === 'inline-1' ? adSizeNativeMappings['320,50'] : adSizeNativeMappings['320,100']
    },
    [AdPageType.MY_TICKETS]: {
      responsiveMappingName: 'leaderboard',
      desktop:
        pos === 'inline-1'
          ? [
              [970, 90],
              [728, 90]
            ]
          : [728, 90],
      tablet: [728, 90],
      phone: pos === 'inline-1' ? [320, 50] : [320, 100],
      native: pos === 'inline-1' ? adSizeNativeMappings['320,50'] : adSizeNativeMappings['320,100']
    },
    [AdPageType.PROFILE]: {
      desktop: [300, 250],
      tablet: [300, 250],
      phone: [300, 250],
      native: adSizeNativeMappings['300,250']
    },
    [AdPageType.FAVORITES]: {
      responsiveMappingName: 'leaderboard',
      desktop:
        pos === 'inline-1'
          ? [
              [970, 90],
              [728, 90]
            ]
          : [728, 90],
      tablet: [728, 90],
      phone: pos === 'inline-1' ? [320, 50] : [320, 100],
      native: pos === 'inline-1' ? adSizeNativeMappings['320,50'] : adSizeNativeMappings['320,100']
    },
    [AdPageType.OTHER]: {
      responsiveMappingName: 'leaderboard',
      desktop: [
        [970, 90],
        [728, 90]
      ],
      tablet: [728, 90],
      phone: [320, 50],
      native: adSizeNativeMappings['320,50']
    },
    [AdPageType.ORDER_CHECKOUT]: {
      responsiveMappingName: 'custom',
      desktop: [[535, 95]],
      tablet: [535, 95],
      phone: [320, 100],
      native: adSizeNativeMappings['320,100']
    }
  };
  return definedAdMappings[adPageType];
};

const getAdSize = (
  adPageType: AdPageType,
  isTablet: boolean,
  isNative: boolean,
  isMobileWeb: boolean,
  pos: Pos = 'inline-1'
) => {
  const responsiveAdSizes = getDefinedAdMappings(adPageType, pos);
  if (isNative) {
    // native + tablet
    return adSizeNativeMappings[responsiveAdSizes.phone.toString()];
  } else if (isTablet) {
    // web tablet
    return responsiveAdSizes.tablet;
  } else if (isMobileWeb) {
    return responsiveAdSizes.phone;
  } else {
    return responsiveAdSizes.desktop;
  }
};

export const marginMap = (isMobileWeb: boolean): { [key: string]: { top: number; bottom: number } } => ({
  hp: {
    top: 0,
    bottom: 16
  },
  'event-list': {
    top: isMobileWeb ? 8 : 16,
    bottom: isMobileWeb ? 0 : 16
  },
  'order-confirmation': {
    top: 0,
    bottom: 0
  },
  mytickets: {
    top: 0,
    bottom: 32
  },
  profile: {
    top: 16,
    bottom: 0
  },
  favorites: {
    top: 0,
    bottom: isMobileWeb ? 32 : 28
  },
  other: {
    top: 0,
    bottom: 0
  },
  'order-checkout': {
    top: isMobileWeb ? 32 : 0,
    bottom: 0
  }
});

export const marginMapNative = (pos?: Pos): { [key: string]: { top: number; bottom: number } } => {
  return {
    hp: {
      top: 0,
      bottom: 16
    },
    'event-list': {
      top: 8,
      bottom: 0
    },
    'order-confirmation': {
      top: 0,
      bottom: 0
    },
    mytickets: {
      top: 0,
      bottom: pos === 'inline-2' ? 32 : 0
    },
    profile: {
      top: 16,
      bottom: 0
    },
    favorites: {
      top: 0,
      bottom: 0
    },
    other: {
      top: 0,
      bottom: 0
    },
    'order-checkout': {
      top: 0,
      bottom: 0
    }
  };
};
