import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';

export type ContextKind = 'event' | 'school' | 'user'; // Extend this type when adding new context use cases.

export type LaunchDarklyContextAttributes = {
  readonly [key: string]: string | boolean | undefined | string[] | number[] | number;
  eventId?: string | number;
  schoolId?: string;
  schoolIds?: string[];
  eventIds?: number[];
  anonymous?: boolean;
  fanEmail?: string;
  name?: string;
  fanFirstName?: string;
  fanLastName?: string;
};

export type LaunchDarklyContextUpdate = {
  kind: ContextKind;
  key: string;
  attributes: LaunchDarklyContextAttributes;
};

const batchContextKindHasChanged = ({
  updates,
  ldContext
}: {
  updates: LaunchDarklyContextUpdate[];
  ldContext: any;
}) => {
  return updates.some(update => {
    const currentContext = ldContext[update.kind];
    if (!currentContext) return true;
    return (
      JSON.stringify(currentContext) !==
      JSON.stringify({
        kind: update.kind,
        key: update.key,
        ...update.attributes
      })
    );
  });
};

export const useLaunchDarklyContextSync = (updates: LaunchDarklyContextUpdate[]) => {
  const { updateLaunchDarklyContext, ldContext, isReady } = useLaunchDarklyContext();
  const hasProcessed = useRef(false);

  const lastUpdatesRef = useRef<LaunchDarklyContextUpdate[]>(updates);

  useEffect(() => {
    if (!isEqual(lastUpdatesRef.current, updates)) {
      lastUpdatesRef.current = updates;
    }
  }, [updates]);

  useEffect(() => {
    if (
      isReady &&
      (!hasProcessed.current || batchContextKindHasChanged({ updates: lastUpdatesRef.current, ldContext }))
    ) {
      lastUpdatesRef.current.forEach(update => {
        updateLaunchDarklyContext(update.kind, update.key, update.attributes);
      });
      hasProcessed.current = true;
    }

    return () => {
      hasProcessed.current = false;
    };
  }, [isReady, ldContext, updateLaunchDarklyContext, updates]);
};
