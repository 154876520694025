import React from 'react';
import { useAuthentication, useMediaQuery, useNavigate, useChatBot } from '@gf/cross-platform-lib/hooks';
import { ProfileAccordionSubHeaderAction } from '../profileInterfaces';
import { GOFAN_APP_PAGES, NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { Linking } from 'react-native';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';
import { PROFILE, useDrawerContext, useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import LetsChat from '@gf/cross-platform-lib/components/Icons/LetsChat';

enum ProfileHeaders {
  FAN_DETAILS = 'Fan details',
  ORDERS = 'Orders',
  MORE_RESOURCES = 'More resources'
}

const GOFAN_SUPPORT_URL = 'https://fansupport.gofan.co/hc/en-us';

export const useGetProfileMenuInfo = () => {
  const { isFanSignedIn } = useAuthentication();
  const { isMobile, isNativeApp } = useMediaQuery();
  const { openChatBot } = useChatBot();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawerContext();
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: launchDarklyOrderHistoryEnabled } = launchDarklyContext.features['order-history-enabled'];
  const openPreferenceCenter = () => {
    (window as Window & { OneTrust?: { ToggleInfoDisplay: () => void } }).OneTrust?.ToggleInfoDisplay();
  };

  const headersToSubHeadersActions: Map<
    string,
    { expandable: boolean; subHeaderAction: ProfileAccordionSubHeaderAction[] }
  > = new Map<string, { expandable: boolean; subHeaderAction: ProfileAccordionSubHeaderAction[] }>();
  headersToSubHeadersActions.set(ProfileHeaders.FAN_DETAILS, {
    expandable: false,
    subHeaderAction: Array.from([
      {
        subHeader: 'Favorites',
        action: () => {
          navigate(GOFAN_APP_PAGES['my-schools'].getPath());
          !isMobile && closeDrawer(PROFILE);
        }
      },
      {
        subHeader: 'Your Privacy Choices',
        action: () => {
          openPreferenceCenter();
        }
      }
    ])
  });
  headersToSubHeadersActions.set(ProfileHeaders.ORDERS, {
    expandable: false,
    subHeaderAction: Array.from([
      {
        subHeader: 'Tickets',
        action: () => {
          navigate(GOFAN_APP_PAGES.myTickets.getPath());
          !isMobile && closeDrawer(PROFILE);
        }
      }
    ])
  });
  headersToSubHeadersActions.set(ProfileHeaders.MORE_RESOURCES, {
    expandable: true,
    subHeaderAction: Array.from([
      {
        iconLeft: <LetsChat height={24} width={24} fill={'#000000'} />,
        subHeader: 'Need help?',
        action: () => openChatBot()
      },
      {
        subHeader: 'GoFan Help Center',
        action: () => {
          Linking.openURL(GOFAN_SUPPORT_URL).catch(err => {
            recordError(err, {
              originatingFunction: 'Link-followHref',
              customMessage: 'Failed to open external URL.',
              errorGroup: NEW_RELIC_ERROR_GROUPS.Navigation,
              data: { href: GOFAN_SUPPORT_URL }
            });
          });
        }
      },
      {
        subHeader: 'Privacy policy',
        action: () => {
          const href = `${GetCurrentApplicationConfiguration().origin.urlWeb}${GOFAN_APP_PAGES['privacy-policy'].path}`;
          Linking.openURL(href).catch(err => {
            recordError(err, {
              originatingFunction: 'Link-followHref',
              customMessage: 'Failed to open external URL.',
              errorGroup: NEW_RELIC_ERROR_GROUPS.Navigation,
              data: { href }
            });
          });
          !isMobile && closeDrawer(PROFILE);
        }
      },
      {
        subHeader: 'Terms of use',
        action: () => {
          const href = `${GetCurrentApplicationConfiguration().origin.urlWeb}${GOFAN_APP_PAGES['terms-of-use'].path}`;
          Linking.openURL(href).catch(err => {
            recordError(err, {
              originatingFunction: 'Link-followHref',
              customMessage: 'Failed to open external URL.',
              errorGroup: NEW_RELIC_ERROR_GROUPS.Navigation,
              data: { href }
            });
          });
          !isMobile && closeDrawer(PROFILE);
        }
      }
    ])
  });

  if (isFanSignedIn()) {
    const fanDetailsSubHeadersToActions = headersToSubHeadersActions.get(ProfileHeaders.FAN_DETAILS)!.subHeaderAction;
    fanDetailsSubHeadersToActions.unshift({
      subHeader: 'Personal information',
      action: () => {
        navigate(GOFAN_APP_PAGES.profileMyInfo.getPath());
        !isMobile && closeDrawer(PROFILE);
      }
    });
    fanDetailsSubHeadersToActions.pop();
    fanDetailsSubHeadersToActions.push({
      subHeader: 'Notifications',
      action: () => {
        navigate(GOFAN_APP_PAGES.profileNotifications.getPath());
        !isMobile && closeDrawer(PROFILE);
      }
    });
    fanDetailsSubHeadersToActions.push({
      subHeader: 'Your Privacy Choices',
      action: () => {
        openPreferenceCenter();
        !isMobile && closeDrawer(PROFILE);
      }
    });

    if (launchDarklyOrderHistoryEnabled) {
      const ordersSubHeadersToActions = headersToSubHeadersActions.get(ProfileHeaders.ORDERS)!.subHeaderAction;
      ordersSubHeadersToActions.push({
        subHeader: 'Order history',
        action: () => {
          navigate(GOFAN_APP_PAGES.profileOrderHistory.getPath());
          !isMobile && closeDrawer(PROFILE);
        }
      });
    }

    if (isNativeApp) {
      const moreResourcesSubHeadersToActions = headersToSubHeadersActions.get(
        ProfileHeaders.MORE_RESOURCES
      )!.subHeaderAction;
      moreResourcesSubHeadersToActions.push({
        subHeader: 'Delete account',
        action: () => {}
      });
    }
  }
  return headersToSubHeadersActions;
};

export default useGetProfileMenuInfo;
